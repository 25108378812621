/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(window).load(function(){
          $('#mainNav ul li a').prepend('<i class="fa fa-chevron-circle-down"></i>&nbsp;');
          $('.attachments').appendTo($('.downloads'));
          $('.inThisSection li a').prepend('<i class="fa fa-chevron-circle-right"></i>');
          open_search_event();
          $('.curved').circleType({radius: 80});
        });

        $(document).ready(function(){
            $("#searchWrap button.search-submit").prop('disabled', true );
            $("span").removeAttr( 'style' );
        });

        (function($) {
            $.fn.toggleDisabled = function(){
                return this.each(function(){
                    this.disabled = !this.disabled;
                });
            };
        })(jQuery);

        var open_search_event = function(){
          $('#searchWrap .search-icon').on('touchstart click', function(){
            $('#searchWrap').toggleClass('openSearchBar');
            setTimeout(function(){
              $('#searchWrap .search-icon').toggleClass('close-icon');
              var vals = ['true', 'false'];
              $('#searchWrap button.search-submit').toggleDisabled();
              /*if( $("#searchWrap.openSearchBar button.search-submit").attr('disabled') === 'disabled' ){
                console.log('remove disabled');
                $("#searchWrap.openSearchBar button.search-submit").disabled = true;
              }else{
                console.log('add disabled');
                 $("#searchWrap.openSearchBar button.search-submit").prop('disabled', true);
              }*/

            }, 400);
          });
        };
        // JavaScript to be fired on all pages
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'psge_id_134': {
      init: function() {

        $(window).load(function(){
         $('body.page-id-134').find('img').each(function(){
          var imgClass = (this.width/this.height > 1) ? 'wide' : 'tall';
          $(this).addClass('imgClass');
         });
        });

      }
    },
    'single_post': {
      init: function() {
        $('li.menu-blog').addClass('active');
      }
    },
    'single_projects': {
      init: function(){
        $('li.menu-what-we-do').addClass('active');
      }
    },
    'page_template_template_archive_parent': {
      init: function() {

        //Detect Image Orientation
        var image_orientation = function(img){
          //console.log(img);
          var image = img;
          if (image.width() > image.height()){
              image.addClass('landscape');
          } else if (image.width() < image.height()){
              image.addClass('portrait');
          } else {
             image.addClass('square');
          }
        };

        $('.archivePostImg img').each(function( ){
          image_orientation( $(this) );
        });

      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
